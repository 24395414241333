import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { Row, Col, Tab } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../index.css";

// import logo from "../images/draft website.png";
import logo from "../images/logo-landing.png";
import img1 from "../images/img-1.png";
import img2 from "../images/img-2.png";
import img3 from "../images/img-3.png";
import thumb1 from "../images/thumb1.png";
import thumb2 from "../images/thumb2.png";
import thumb3 from "../images/thumb3.png";
import welcome from "../images/welcome.png";
import launch from "../images/launch.png";
import logonama from "../images/logo-nama.png";
import reason from "../images/reason.png";
import its from "../images/its.png";
import bio from "../images/biologi.png";
import incubator from "../images/incubator.jpeg";
import kemenkop from "../images/kemenkop.jpeg";
import footerLast from "../images/footer.png";
import {
  faQuoteLeft,
  faQuoteRight,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

import Navbar_Landing from "../components/navbar_landing";

function Landing() {
  const buttonStyle = {
    backgroundColor: "#FFFFFF",
    fontWeight: "bold",
    borderColor: "#a9cf4e",
    color: "#3F983E",
    borderRadius: "50px",
  };

  const buttonStylefooter = {
    backgroundColor: "#50800B",
    fontWeight: "bold",
    borderColor: "#a9cf4e",
    color: "#FFFFFF",
    borderRadius: "50px",
    // transition: "background-color 0.3s ease", // Add a transition for a smooth color change
    "&:hover": {
      backgroundColor: "blue !important", // Change the background color on hover
    },
  };

  const handleMouseEnter = (event) => {
    event.target.style.backgroundColor = "#50800B";
  };

  const handleMouseLeave = (event) => {
    event.target.style.backgroundColor = "rgba(80, 128, 11, 0.70)";
  };

  const [isTablet, setisTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setisTablet(window.innerWidth <= 576);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div
        style={{
          background: "linear-gradient(311deg, #BFC94E 0%, #50800B 100%)",
        }}
      >
        <Navbar_Landing />
        {/* desktop 1 */}
        <div className="pt-5" id="top">
          <div className="pt-5">
            <div className="container-fluid">
              <Row>
                {/* <Col sm={1}></Col> */}
                <Col sm={12} md={6} xl={6}>
                  <div className="d-flex justify-content-center align-items-center flex-column ">
                    <h1 style={{ color: "#FFFFFF", fontSize: "10vw" }}>
                      Launching
                    </h1>
                    <h1
                      className="pb-4"
                      style={{ color: "#FFFFFF", fontSize: "10vw" }}
                    >
                      Soon!
                    </h1>
                    <p
                      className="text-center fs-5"
                      style={{ color: "#FFFFFF" }}
                    >
                      {" "}
                      In the memantime, Sign up for our monthly newsletter to{" "}
                      <b>stay up date</b>{" "}
                    </p>
                  </div>
                  <Row className="justify-content-center align-items-center">
                    <Col xs="auto">
                      <Button
                        className="btn btn-lg mb-4 mb-sm-0 px-4"
                        style={buttonStyle}
                        href="https://bit.ly/ReachOutAither"
                      >
                        Notify Me
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col
                  sm={12}
                  md={6}
                  xl={6}
                  className="d-flex justify-content-end pt-sm-4 pe-0"
                >
                  <img
                    src={logo}
                    alt="ilustrasi"
                    className="img-fluid"
                    style={{ maxWidth: "70%", height: "auto" }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>

        {/* desktop 2*/}
        <div className="pt-5" id="home">
          <div className="container-fluid">
            <div className="d-flex justify-content-center align-items-center flex-column py-2">
              <img src={welcome} className="img-fluid" />
            </div>
            <div className="container">
              <div className="d-flex justify-content-start">
                <p
                  className="fs-3"
                  style={{ backgroundColor: "#E13737", color: "#FFFFFF" }}
                >
                  Your Indoor Plants!
                </p>
              </div>
              <div className="d-flex justify-content-center px-5 px-md-1 px-sm-1">
                <p
                  className="pb-5"
                  style={{
                    color: "#FFFFFF",
                    textAlign: "justify",
                    fontSize: "3ex",
                  }}
                >
                  We use <b>micro-algae biotechnology</b> to convert carbon
                  emissions into high-quality oxygen, providing <b>clean air</b>{" "}
                  that's convenient{" "}
                  <b>for families with pregnant women and infants.</b> Our
                  mission is to improve respiratory health and reduce carbon
                  footprint, all while making it easier for families to{" "}
                  <b>breathe easy.</b>
                </p>
              </div>
            </div>
            <br /> <br /> <br /> <br /> <br /> <br />
          </div>
        </div>

        {/* desktop 3*/}
        <div className="py-5" id="qna">
          <div className="container">
            <div class="card mb-4">
              <div class="card-body px-5">
                <div className="d-flex justify-content-around align-items-center gap-5">
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 order-lg-2">
                      <div className="d-flex justify-content-center">
                        <img
                          src={img1}
                          alt="Image"
                          height="150px"
                          width="150px"
                          class="rounded-circle"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-10 order-lg-1 px-2 align-self-center">
                      <h5 class="card-title fs-3 fw-bolder">
                        Do you want to protect your baby and family from air
                        pollution at home?
                      </h5>
                      <p class="fs-6">
                        According to the U.S. EPA, indoor air can be 2-5 times
                        more polluted than outdoor air. Meanwhile, the child's
                        immune system is immature and very vulnerable to
                        bacteria, fungi, and viruses.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-4">
              <div class="card-body px-5">
                <div className="d-flex justify-content-around align-items-center gap-5">
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 order-lg-1">
                      <div className="d-flex justify-content-center">
                        <img
                          src={img2}
                          alt="Image"
                          height="150px"
                          width="150px"
                          className="rounded-circle"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-10 order-lg-2 px-2 align-self-center">
                      <h5 class="card-title fs-3 fw-bolder">
                        Struggling to find time for cleaning house on your own?
                      </h5>
                      <p className="fs-6">
                        To protect the house from dust, bacteria and viruses
                        that harm the family. Regular cleaning is required every
                        day for floors and 2 times a week for every corner of
                        the room.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-4">
              <div class="card-body px-5">
                <div className="d-flex justify-content-around align-items-center gap-5">
                  <div className="row">
                    <div class="col-sm-12 col-lg-2 order-lg-2">
                      <div className="d-flex justify-content-center">
                        <img
                          src={img3}
                          alt="Image"
                          height="150px"
                          width="150px"
                          className="rounded-circle"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-10 order-lg-1 px-2 align-self-center">
                      <h5 class="card-title fs-3 fw-bolder">
                        Didn't had enough space for plants at home?
                      </h5>
                      <p className="fs-6">
                        Growing plants at home can purify the air, improve
                        aesthetics, and nourish the heart and mind. Five plants
                        in your home can purify the air by 75%, according to
                        research by Plant Life Balance and RMIT University
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* desktop 4 */}
        <div className="py-5" id="benefit">
          <div className="container">
            <div className="header-content">
              <div className="d-flex justify-content-center">
                <img src={reason} className="img-fluid" />
              </div>
            </div>
            <div className="row justify-content-center gy-4 mt-4">
              <div class="col-sm-12 col-xl-6">
                <div className="card">
                  <div class="card-body px-5">
                    <div className="d-flex justify-content-around align-items-center gap-5">
                      <div class="row">
                        <div class="col-sm-12 col-md-4">
                          <div className="d-flex justify-content-center">
                            <img
                              src={thumb1}
                              alt="Image"
                              height="160px"
                              width="160px"
                              class="rounded"
                            />
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-8 align-self-center text-sm-center text-md-start">
                          <h5 class="card-title fs-4 fw-bolder">
                            Giving High Quality O2
                          </h5>
                          <p class="fs-6">
                            Aither converts CO2 to O2 that is 10 times more
                            powerful than an 11-year-old tree.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-xl-6">
                <div className="card">
                  <div class="card-body px-5">
                    <div className="d-flex justify-content-around align-items-center gap-5">
                      <div class="row">
                        <div class="col-sm-12 col-md-4">
                          <div className="d-flex justify-content-center">
                            <img
                              src={thumb2}
                              alt="Image"
                              height="160px"
                              width="160px"
                              className="rounded"
                            />
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-8 align-self-center text-sm-center text-md-start">
                          <h5 class="card-title fs-3 fw-bolder">Healthy</h5>
                          <p className="fs-6">
                            Aither absorbs 0.3 micron bacteria, fungi, and
                            viruses with high tech HEPA Filter.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-xl-6">
                <div className="card">
                  <div class="card-body px-5">
                    <div className="d-flex justify-content-around align-items-center gap-5">
                      <div class="row">
                        <div class="col-sm-12 col-md-4">
                          <div className="d-flex justify-content-center">
                            <img
                              src={thumb3}
                              alt="Image"
                              height="160px"
                              width="160px"
                              className="rounded"
                            />
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-8 align-self-center text-sm-center text-md-start">
                          <h5 class="card-title fs-3 fw-bolder">Affordable</h5>
                          <p className="fs-6">
                            Our price is competitive with the other air purifier
                            that have the same technology.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* desktop 5 */}
        <div id="support" className="py-5 support">
          <div className="pt-5">
            <div className="container">
              <div className="card">
                <div className="card-title d-flex justify-content-start">
                  <div
                    className="box my-4 px-4 pt-1"
                    style={{ borderRadius: "0 1.5rem 1.5rem 0" }}
                  >
                    <h1
                      className=""
                      style={{
                        color: "#FFFFFF", // Text color (white)
                        // backgroundColor: "#669965", // Background color (green)
                        fontWeight: "bold",
                      }}
                    >
                      SUPPORTED BY
                    </h1>
                  </div>
                </div>
                <div className="card-body">
                  <Row style={{ rowGap: "1rem" }}>
                    <Col sm={12} lg={6}>
                      <Card
                        border="success"
                        style={{ border: "none" }}
                        className="p-1"
                      >
                        <Card.Body>
                          <Row>
                            <Col
                              sm={4}
                              md={4}
                              lg={4}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <img
                                src={its}
                                alt="Image"
                                className="image-fluid"
                                style={{ maxWidth: "70%", height: "auto" }}
                              />
                            </Col>
                            <Col
                              sm={8}
                              md={8}
                              lg={8}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <div
                                style={{ color: "#515D33" }}
                                className="mt-2"
                              >
                                <div
                                  style={{ height: "4rem" }}
                                  className="d-flex align-items-center"
                                >
                                  <Card.Title className="fs-5 mb-2">
                                    Sepuluh Nopember Institute Of Technology
                                  </Card.Title>
                                </div>
                                <hr className="divider" />
                                <Card.Text>
                                  <p>
                                    Teknik Kimia Street, Surabaya
                                    <br />
                                    East Java, Indonesia
                                    <br />
                                    60111
                                  </p>
                                </Card.Text>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={12} lg={6}>
                      <Card
                        border="success"
                        style={{ border: "none" }}
                        className="p-1"
                      >
                        <Card.Body>
                          <Row>
                            <Col
                              sm={4}
                              md={4}
                              lg={4}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <img
                                src={bio}
                                alt="Image"
                                className=" image-fluid"
                                style={{ maxWidth: "70%", height: "auto" }}
                              />
                            </Col>
                            <Col
                              sm={8}
                              md={8}
                              lg={8}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <div
                                style={{ color: "#515D33" }}
                                className="mt-2"
                              >
                                <div
                                  style={{ height: "4rem" }}
                                  className="d-flex align-items-center"
                                >
                                  <Card.Title className="fs-6 mb-2">
                                    Biology Department of Sepuluh Nopember
                                    Institute of Technology
                                  </Card.Title>
                                </div>
                                <hr className="divider" />
                                <Card.Text>
                                  <p>
                                    Teknik Kimia Street, Surabaya
                                    <br />
                                    East Java
                                    <br />
                                    60111
                                  </p>
                                </Card.Text>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={12} lg={6}>
                      <Card
                        border="success"
                        style={{ border: "none" }}
                        className="p-1"
                      >
                        <Card.Body>
                          <Row>
                            <Col
                              sm={4}
                              md={4}
                              lg={4}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <img
                                src={incubator}
                                alt="Image"
                                className="image-fluid"
                                style={{ maxWidth: "70%", height: "auto" }}
                              />
                            </Col>
                            <Col
                              sm={8}
                              md={8}
                              lg={8}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <div
                                style={{ color: "#515D33" }}
                                className="mt-2"
                              >
                                <div
                                  style={{ height: "4rem" }}
                                  className="d-flex align-items-center"
                                >
                                  <Card.Title className="fs-6 mb-2">
                                    Business Incubator of Sepuluh Nopember
                                    Institute of Technology
                                  </Card.Title>
                                </div>
                                <hr className="divider" />
                                <Card.Text>
                                  <p>
                                    Science Technopark Sepuluh Nopember
                                    Institute of Technology, Surabaya
                                    <br />
                                    East Java
                                    <br />
                                    60111
                                  </p>
                                </Card.Text>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={12} lg={6}>
                      <Card
                        border="success"
                        style={{ border: "none" }}
                        className="p-1"
                      >
                        <Card.Body>
                          <Row>
                            <Col
                              sm={4}
                              md={4}
                              lg={4}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <img
                                src={kemenkop}
                                alt="Image"
                                className="image-fluid"
                                style={{ maxWidth: "70%", height: "auto" }}
                              />
                            </Col>
                            <Col
                              sm={8}
                              md={8}
                              lg={8}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <div
                                style={{ color: "#515D33" }}
                                className="mt-2"
                              >
                                <div
                                  style={{ height: "4rem" }}
                                  className="d-flex align-items-center"
                                >
                                  <Card.Title className="fs-6 mb-2">
                                    Ministry of Cooperatives and SMEs Republic
                                    of Indonesia
                                  </Card.Title>
                                </div>
                                <hr className="divider" />
                                <Card.Text>
                                  <p>
                                    H. R. Rasuna Said Street Kav. 3-4,
                                    <br />
                                    Jakarta 12940, Indonesia
                                  </p>
                                </Card.Text>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* desktop 6 */}
        <div id="contact" className="py-5">
          <div className="pt-5">
            <div className="container">
              <div className="card">
                <div className="card-body">
                  <div className="header-content">
                    <div className="d-flex justify-content-center py-5">
                      <img
                        src={footerLast}
                        alt="Image"
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </div>
                    {/* <div className="d-flex justify-content-center">
                      <p
                        className="fs-4 fw-bolder"
                        style={{ color: "#515D33" }}
                      >
                        Very
                      </p>
                    </div>
                    <div className="d-flex justify-content-center align-self-center">
                      <div>
                        <p
                          className="fs-3 m-0 fw-bolder text-end"
                          style={{ color: "#515D33" }}
                        >
                          AITHER
                        </p>
                        <p
                          className="fs-3 m-0 text-end"
                          style={{ color: "#515D33" }}
                        >
                          Will Come <br />
                          To You
                        </p>
                      </div>
                      <p
                        className="display-1 fw-bolder"
                        style={{ color: "#515D33" }}
                      >
                        Soon
                      </p>
                    </div> */}
                    <div className="d-flex justify-content-center">
                      <p
                        className="fs-2 fw-bolder m-0 text-end"
                        style={{ color: "#39BEAB" }}
                      >
                        We'd love to Hear From You
                      </p>
                    </div>
                    <div className="d-flex justify-content-center mb-4">
                      <p
                        className="fs-5 m-0 text-center"
                        style={{ color: "#000000" }}
                      >
                        Let us know if you have any suggestion or curiosity
                        about our Features, Product, and Project <br />{" "}
                        <b>We're ready to answer all of your question!</b> ❤️
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="row gap-1">
                        <Col md="auto">
                          <div className="d-flex justify-content-center">
                            <Button
                              className="btn btn-xl mb-4 mb-sm-0"
                              style={buttonStylefooter}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              href="#home"
                            >
                              Back To Homepage
                            </Button>
                          </div>
                        </Col>
                        <Col md="auto">
                          <div className="d-flex justify-content-center">
                            <Button
                              className="btn btn-xl mb-4 mb-sm-0"
                              style={buttonStylefooter}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              href="https://bit.ly/ReachOutAither"
                            >
                              Read Our Article
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </div>
                  <div class="row pt-4">
                    <div class="col-sm-12 col-md-7 col-lg-8 col-xl-9">
                      <p class="footer-text">
                        Science Technopark ITS 2nd Floor
                      </p>
                      <p class="footer-text">Surabaya, Indonesia 60111</p>
                    </div>
                    <div class="col-sm-12 col-md-5 col-lg-4 col-xl-3">
                      <a
                        href="https://www.instagram.com/aitherindonesia/"
                        className="footer-text"
                        style={{ textDecoration: "none" }}
                      >
                        <div className="d-flex justify-content-start">
                          <div style={{ paddingTop: "0.1rem" }}>
                            <FontAwesomeIcon
                              icon={faInstagram}
                              className="pt-1 px-1"
                            />
                          </div>
                          <p className="footer-text">@aitherindonesia</p>
                        </div>
                      </a>
                      <a
                        href="mailto:microalga.project@gmail.com"
                        className="footer-text"
                        style={{ textDecoration: "none" }}
                      >
                        <div className="d-flex justify-content-start">
                          <div style={{ paddingTop: "0.1rem" }}>
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              className="px-1"
                            />
                          </div>
                          <p className="footer-text">
                            microalga.project@gmail.com
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Landing;
