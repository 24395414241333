import React from 'react';
import '../index.css'

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';

function Navbar_Landing() {
    const navbarStyle = {
        opacity: 0.8,
        backgroundColor: '#22692C'
      };

    return (
        <>
            <Navbar expand="lg" fixed="top" style={navbarStyle}>
                <Container>
                <Navbar.Brand href="#top">
                    {/* <img src={logo} alt="Logo micro Alga" width='150rem' height='80rem' /> */}
                    <h1 className="fs-2 logoNav" style={{ color: 'white', fontWeight: 'bold' }}>AITHER</h1>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Nav className="custom-nav">
                        <Nav.Link href="#home"  className='fs-5' >About Us</Nav.Link>
                        <span className="nav-divider"></span>
                        <Nav.Link href="#support" className='fs-5' >Supported By</Nav.Link>
                        <span className="nav-divider"></span>
                        <Nav.Link href="#contact" className='fs-5' >Contact Us</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default Navbar_Landing;
